var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro-y tab-content mt-5"},[_c('div',{staticClass:"tab-content__pane active"},[_c('div',{staticClass:"grid grid-cols-12 gap-6"},[(_vm.isLoading)?_c('div',{staticClass:"col-span-12"},[(_vm.isLoading)?_c('Loader'):_vm._e()],1):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"intro-y box col-span-12"},[_vm._m(0),_c('div',{staticClass:"px-1"},[(_vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y overflow-auto lg:overflow-visible"},[_c('table',{staticClass:"table table-report sm:mt-2"},[_vm._m(1),_c('tbody',_vm._l((_vm.assetsList),function(asset,index){return _c('tr',{key:index,staticClass:"intro-x zoom-in"},[_c('td',{on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/assets/" + (asset.id)),
                      })}}},[_c('a',{staticClass:"font-medium whitespace-no-wrap",attrs:{"href":""}},[_vm._v(_vm._s(asset.brand))]),_c('div',{staticClass:"text-gray-400 text-xs whitespace-no-wrap"},[_vm._v(" "+_vm._s(asset.model)+" ")])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/assets/" + (asset.id)),
                      })}}},[_vm._v(" "+_vm._s(asset.tag_id)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/assets/" + (asset.id)),
                      })}}},[_vm._v(" "+_vm._s(asset.serial_no)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/assets/" + (asset.id)),
                      })}}},[_vm._v(" "+_vm._s(asset.category)+" ")]),_c('td',{staticClass:"w-40",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/assets/" + (asset.id)),
                      })}}},[_c('div',{staticClass:"flex items-center justify-center text-theme-9"},[_vm._v(" "+_vm._s(asset.yom)+" ")])]),_c('td',{staticClass:"w-40",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/assets/" + (asset.id)),
                      })}}},[_c('div',{staticClass:"flex items-center justify-center"},[_c('span',{staticClass:"py-1 px-2 rounded text-xs bg-theme-1 text-white"},[_vm._v(" "+_vm._s(asset.created_on.substring( 0, asset.created_on.indexOf("T") )))])])])])}),0)])]):_vm._e()])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v("Lease Assets")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-no-wrap"},[_vm._v("BRAND & MODEL")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("ASSET NO")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("SERIAL NO")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("CATEGORY")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("YOM")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("CREATED ON")])])])}]

export { render, staticRenderFns }