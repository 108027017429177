
































































import { Component, Vue } from "vue-property-decorator";
import { ArrowLeftIcon, RefreshCcwIcon, EditIcon } from "vue-feather-icons";

import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";
import LeasePreview from "../components/lease-detail/LeasePreview.vue";
import LeaseDashboard from "../components/lease-detail/Lease-Dashboard.vue";
import LeaseAssets from "../components/lease-detail/Lease-Assets.vue";
import LeaseOperators from "../components/lease-detail/Lease-Operators.vue";

@Component({
  components: {
    ArrowLeftIcon,
    RefreshCcwIcon,
    Loader,
    NoData,
    LeasePreview,
    LeaseDashboard,
    EditIcon,
    LeaseAssets,
    LeaseOperators,
  },
})
export default class LeaseDetail extends Vue {
  isLoading = true;
  showNoData = false;
  noDataType = "Error";
  basicDetailData = "";
  selectedTab = 0;

  mounted() {
    this.fetchAssetWithId();
  }

  reloadData() {
    this.isLoading = true;
    this.showNoData = false;
    this.fetchAssetWithId();
    this.selectedTab = 0;
  }

  onChangeTab(index: number) {
    this.selectedTab = index;
  }

  async fetchAssetWithId() {
    const id = this.$router.currentRoute.params.id;
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/lease/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
      } else {
        this.basicDetailData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.basicDetailData = "";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }
}
