








































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LeaseDashboard extends Vue {
  @Prop() basicDetailData!: any;
}
