


















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CalendarIcon, MapPinIcon, DollarSignIcon } from "vue-feather-icons";

@Component({
  components: {
    CalendarIcon,

    MapPinIcon,
    DollarSignIcon,
  },
})
export default class LeasePreview extends Vue {
  @Prop() basicDetailData!: {};
  @Prop() onChangeTab!: Function;

  activeTab = 0;
  tabs = ["Dashboard", "Assets", "Operators"];

  onClickTab(index: number) {
    this.activeTab = index;
    this.onChangeTab(index);
  }
}
