




















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Loader from "../ui/Loader.vue";

@Component({
  components: {
    Loader,
  },
})
export default class LeaseOperators extends Vue {
  @Prop() assetsList: any;
  @Prop() realAssetsList: any;
  isLoading = false;
}
